<div class="border-surface border rounded shadow-sm flex flex-col table-container h-full">
  <p-table #dt [breakpoint]="'960px'" class="flex h-full" [resizableColumns]=" true"
    [(selection)]="_tableService.selected" [paginator]="pagination" [rows]="rowCount" [showCurrentPageReport]="true"
    currentPageReportTemplate="{first} - {last} de {totalRecords}" [rowsPerPageOptions]="[10, 20, 40]"
    selectionMode="single" [columns]="columns" [value]="dataRow" [exportFilename]="exportFileName" [scrollable]="true"
    [scrollHeight]="tableScrollHeight" [tableStyle]="{ 'min-width': '20rem' , }">
    @if (caption) {
    <ng-template pTemplate="caption">
      <div class="flex gap-3 justify-between">
        <div>
          @if (arrowPagination) {
          <p-button type="button" icon="pi pi-chevron-left" (click)="getPageTable.emit('prev')"
            [disabled]="isFirstPage()" text />
          <p-button type="button" icon="pi pi-refresh" (click)="refreshTable.emit()" text />
          <p-button type="button" icon="pi pi-chevron-right" (click)="getPageTable.emit('next')"
            [disabled]="isLastPage()" text />
          } @else {
          <p-button class="mr-2" size="small" tooltipPosition="top" pTooltip="Refrescar" [outlined]="true"
            icon="pi pi-refresh" (click)="refreshTable.emit()" />
          }
          <p-button size="small" icon="pi pi-download" [outlined]="true" tooltipPosition="top" pTooltip="Exportar"
            (click)="dt.exportCSV()" />
        </div>

        <!-- Boton de busqueda -->

        <div class="flex flex-row gap-2 items-center">
          @if (inputSearch) {
          <div class="search-container flex justify-center items-center" [class.expanded]="isSearchOpen()">
            <span class="p-input-icon-left">
              <i class="pi pi-search" (click)="toggleSearch()"></i>
              <input #searchInput pInputText type="text" [(ngModel)]="searchTerm" (blur)="onInputBlur()"
                (input)="applyFilterGlobal($event)" placeholder="Buscar" [class.hidden]="!isSearchOpen()" />
            </span>
          </div>
          }
          <div>
            <ng-content></ng-content>
          </div>
        </div>

        <!---------------------------------------- -->
      </div>
    </ng-template>
    }
    <ng-template pTemplate="header" let-columns>
      <tr>
        @if (selectRow) {
        <th style="width: 4rem">
          <!-- <p-tableHeaderCheckbox/> -->
        </th>
        }
        @for (col of columns; track $index) {
        @if (!filterColum) {
        <th pResizableColumn [pSortableColumn]="col.field">
          <span class="text-sm">
            {{ col.header }}
            <p-sortIcon [field]="col.field" />
          </span>
        </th>
        } @else {
        <th>
          <span class="text-sm">{{ col.header }}</span>
        </th>
        }
        }
        <th class="max-w-12" [class]="options.length > 0 ? 'w-24' : 'w-12'" style="min-width: 2rem" alignFrozen="right"
          pFrozenColumn>
          @if (options.length > 0) {
          <span class="text-sm"> OPCIONES </span>
          }
        </th>
      </tr>
      @if (filterColum) {
      <tr class="w-full">
        @for (col of columns; track $index) {
        <th pResizableColumn>
          <input type="text" pInputText (blur)="onFilter($event, col.field)" [value]="filters[col.field] || ''" />
        </th>
        }
        <th>

        </th>
      </tr>
      }
    </ng-template>
    <ng-template pTemplate="body" let-rowData let-columns="columns">
      <tr>
        @if (selectRow) {
        <td>
          <!-- @if (selectAll) { -->
          <p-tableCheckbox [value]="rowData" />
          <!-- } @else { -->
          <!-- <p-tableRadioButton [value]="rowData" /> -->
          <!-- } -->
        </td>
        } @for (col of columns; track $index) {
        <td>
          @if (col.customColumn && col.customColumn(rowData); as customHtml) {
          <div [innerHTML]="customHtml"></div>
          } @else if (col.field === "attachments") {
          @for (file of col.customElements(rowData); track $index) {
          <i pTooltip="file" tooltipPosition="top" pRipple
            class="pi pi-file cursor-pointer p-text-secondary text-color"></i>
          }
          } @else {
          <span [class]="col.styleClass" class="text-sm font-semibold text-color"
            [class]="col.styleClass ?? 'text-surface-500'">
            @if (col.pipe === "object") {
            {{ _tableTool.getNestedProperty(rowData, col.field) }}
            } @else {
            {{ rowData[col.field] | parseTable: col.pipe ?? "text" }}
            }
          </span>
          }
        </td>
        }
        <td class="surface-0" alignFrozen="right" pFrozenColumn>
          <div class="flex gap-3 justify-center" [classList]="editRow ? 'buttons' : ''">
            @for (button of buttonTable; track $index) {
            <i [pTooltip]="button.text" (click)="button.action(rowData)" tooltipPosition="top" pRipple
              [class]="button.icon" class="cursor-pointer p-text-secondary text-lg"></i>
            }

            @if (editRow) {
            <i pTooltip="Editar" (click)="edit(rowData)" tooltipPosition="top" pRipple
              class="pi pi-pencil cursor-pointer p-text-secondary text-lg px-2"></i>
            }
            @if (deleteRow) {
            <i pTooltip="Eliminar" (click)="remove(rowData)" tooltipPosition="top" pRipple
              class="pi pi-trash cursor-pointer p-text-secondary text-lg"></i>
            }
            @if (options.length > 0) {
            @for (option of options; track $index) {
            @if (option.name === "resend") {
            <i pTooltip="option.name.toUpperCase()" (click)="edit(rowData)" tooltipPosition="top" pRipple
              class="pi cursor-pointer p-text-secondary text-lg px-1" [class]="option.icon"></i>
            } @else {
            <i [pTooltip]="option.name.toUpperCase()" (click)="getOpcion(rowData, option)" tooltipPosition="top" pRipple
              class="pi cursor-pointer p-text-secondary text-lg px-1" [class]="option.icon"></i>
            }
            }
            }
          </div>
        </td>
      </tr>
    </ng-template>

    <ng-template pTemplate="emptymessage">
      <tr>
        <td [attr.colspan]="columns.length + 1" class="h-32 text-center">
          No hay información.
        </td>
      </tr>
    </ng-template>
  </p-table>
</div>
