<div
  id="app-sidebar"
  class="h-full hidden absolute xl:static left-0 top-0 flex-shrink-0 select-none border-right-1 border-slate-600"
  style="width: 280px; z-index: 1000"
>
  <div class="flex flex-col h-full">
    <div
      class="flex items-center px-5 flex-shrink-0"
      style="height: 60px"
    >
      <!-- <img src="assets/logos/logo-encabezado.png" alt="Image" class="h-3 w-1\4" /> -->
    </div>

    <div class="overflow-y-auto py-2">
      @for (father of optionsSidebar(); track $index) {
        <ul class="list-none py-2 px-3 m-0">
          <li>
            <div
              pRipple
              class="p-3 flex items-center justify-between text-color cursor-pointer"
            >
              <span class="font-medium">{{ father.title | uppercase }}</span>
            </div>

            <ul class="list-none p-0 m-0 pl-2 overflow-hidden">
              @for (item of father.children; track $index) {
                @if (item.children?.length) {
                  <li>
                    <a
                      pRipple
                      class="flex items-center cursor-pointer p-3 rounded text-color hover:surface-100 transition-duration-150 transition-colors"
                      pStyleClass="@next"
                      enterFromClass="hidden"
                      enterActiveClass="slidedown"
                      leaveToClass="hidden"
                      leaveActiveClass="slideup"
                    >
                      <i class="pi mr-2" [class]="item.icon"></i>
                      <span class="font-medium">{{ item.title }}</span>
                      <i class="pi pi-chevron-down ml-auto"></i>
                    </a>

                    <ul
                      class="list-none py-0 pl-3 pr-0 m-0 hidden overflow-y-hidden transition-all transition-duration-400 transition-ease-in-out"
                    >
                      @for (itemChild of item.children; track $index) {
                        <li>
                          <a
                            pRipple
                            [routerLink]="[itemChild.route]"
                            routerLinkActive="router-link-active"
                            class="flex items-center cursor-pointer p-3 rounded text-color hover:surface-100 transition-duration-150 transition-colors"
                          >
                            <i class="pi mr-2" [class]="itemChild.icon"></i>
                            <span class="font-medium">{{
                              itemChild.title
                            }}</span>
                            @if (itemChild.chip) {
                              <span
                                class="inline-flex items-center justify-center ml-auto bg-blue-500 text-0 text-sm border-circle"
                                style="min-width: 1.2rem; height: 1.1rem"
                                >3</span
                              >
                            }
                          </a>
                        </li>
                      }
                    </ul>
                  </li>
                } @else {
                  <li>
                    <a
                      [routerLink]="[item.route]"
                      routerLinkActive="router-link-active"
                      pRipple
                      class="flex items-center cursor-pointer p-3 rounded text-color hover:surface-100 transition-duration-150 transition-colors"
                    >
                      <i class="pi mr-2" [class]="item.icon"></i>
                      <span class="font-medium">{{ item.title }}</span>
                      @if (item.chip) {
                        <span
                          class="inline-flex items-center justify-center ml-auto bg-blue-500 text-0 text-sm border-circle"
                          style="min-width: 1.2rem; height: 1.1rem"
                          >3</span
                        >
                      }
                    </a>
                  </li>
                }
              }
            </ul>
          </li>
        </ul>
      }
    </div>
  </div>
</div>
