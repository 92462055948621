import { CommonModule } from '@angular/common';
import {Component, Input} from '@angular/core';
import { PrimengModule } from '@shared/primeng/primeng.module';

@Component({
  selector: 'app-header',
  standalone: true,
  imports: [CommonModule, PrimengModule],
  templateUrl: './header.component.html',
})
export class HeaderComponent {
  @Input({ required: true }) title: string = ''
}
