import { CommonModule } from '@angular/common';
import { Component, inject, OnInit } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterModule } from '@angular/router';
import { BreadcrumbData } from '@data/constants/breadcrumb.constants';

@Component({
  selector: 'app-breadcrumb',
  standalone: true,
  imports: [CommonModule, RouterModule],
  templateUrl: './breadcrumb.component.html',
})
export class BreadcrumbComponent implements OnInit {
  private _router = inject(Router);

  public config: { label: string; route: string }[] = [];

  ngOnInit(): void {
    const currentRouteSnapshot: ActivatedRouteSnapshot =
      this._router.routerState.snapshot.root;

    let breadcrumb: string = '';
    let routeSnapshot: ActivatedRouteSnapshot | null = currentRouteSnapshot;

    while (routeSnapshot) {
      if (routeSnapshot.data['breadcrumb']) {
        breadcrumb = routeSnapshot.data['breadcrumb'];
        break;
      }
      routeSnapshot = routeSnapshot.firstChild;
    }

    this.config = (BreadcrumbData as any)[breadcrumb] ?? [];

    this._router.events.subscribe(() => {
      const updatedSnapshot: ActivatedRouteSnapshot =
        this._router.routerState.snapshot.root;
      let newRouteSnapshot: ActivatedRouteSnapshot | null = updatedSnapshot;

      while (newRouteSnapshot) {
        if (newRouteSnapshot.data['breadcrumb']) {
          breadcrumb = newRouteSnapshot.data['breadcrumb'];
          break;
        }
        newRouteSnapshot = newRouteSnapshot.firstChild;
      }

      this.config = (BreadcrumbData as any)[breadcrumb] ?? [];
    });
  }
}
