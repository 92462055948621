import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'parseTable',
  standalone: true,
})
export class ParseTablePipe implements PipeTransform {
  private pipeDataTable: any = {
    text: (value: any) => value || '----',
    boolean: (bool: boolean) => (bool ? 'Si' : 'No'),
    date: (date: string | Date) => new Date(date).toLocaleDateString(),

  };

  transform(value: unknown, args: string): unknown {
    return this.pipeDataTable[args](value);
  }
}
