import { inject, Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of, switchMap, tap } from 'rxjs';
import { PermisosUsuariosService } from './configuracion/permisos-usuarios.service';
import { AuthService } from '@core/services/api/auth.service';
import { DataUsuarioService } from '@core/services/api/data-usuario.service';
import { StoreTool } from '@shared/tools/store.tool';
import {
  IPermisosUsuarios,
  IChildren,
} from '@data/interfaces/api/configuracion-empresa/permisos-usuario.interface';

interface PermisoState {
  permisos: IPermisosUsuarios[];
  loadData: boolean;
}

const initialState: PermisoState = {
  permisos: [],
  loadData: false,
};

@Injectable({
  providedIn: 'root',
})
export class PermisosService extends StoreTool<PermisoState> {
  private _permisosService = inject(PermisosUsuariosService);
  private _permisoSubject = new BehaviorSubject<IPermisosUsuarios[]>([]); // Tipo específico en lugar de `any[]`
  private _authService = inject(AuthService);
  private _dataUsuarioService = inject(DataUsuarioService);

  constructor() {
    super(initialState);
  }

  public checkPermisos(): Observable<IPermisosUsuarios[]> {
    return this.selectState(({ permisos, loadData }) => ({
      permisos,
      loadData,
    })).pipe(
      switchMap(({ permisos, loadData }) => {
        if (permisos.length === 0 && !loadData) {
          return this._permisosService
            .listarPermisosUsuariosFormulario(
              this._dataUsuarioService.empresaCodigo,
              this._authService.usuario_id,
            )
            .pipe(
              tap(({ data }) => {
                this.setState((state) => ({
                  ...state,
                  permisos: data,
                  loadData: true,
                }));
              }),
              switchMap((response) => of(response.data as IPermisosUsuarios[])),
            );
        }
        return of(permisos);
      }),
      tap((permisos: IPermisosUsuarios[]) =>
        this._permisoSubject.next(permisos),
      ),
    );
  }

  public setPermisos(items: IPermisosUsuarios[]) {
    const permisosUsuarios = items.map((currentItem: IPermisosUsuarios) => ({
      codigo: currentItem.codigo,
      nombre: currentItem.nombre,
      permisos: {
        archivo: {
          children: currentItem.permisos?.archivo?.children
            ? currentItem.permisos.archivo.children.filter(
                (child: IChildren) => child.permiso === 1,
              )
            : [],
        },
        movimientos: {
          children: currentItem.permisos?.movimientos?.children
            ? currentItem.permisos.movimientos.children.filter(
                (child: IChildren) => child.permiso === 1,
              )
            : [],
        },
        consultas: {
          children: currentItem.permisos?.consultas?.children
            ? currentItem.permisos.consultas.children.filter(
                (child: IChildren) => child.permiso === 1,
              )
            : [],
        },
        reportes: {
          children: currentItem.permisos?.reportes?.children
            ? currentItem.permisos.reportes.children.filter(
                (child: IChildren) => child.permiso === 1,
              )
            : [],
        },
      },
    }));

    this._permisoSubject.next(permisosUsuarios);
  }

  public get permisos$(): Observable<IPermisosUsuarios[]> {
    return this.checkPermisos();
  }
}
