export const BreadcrumbData = {
  // Dashboard
  dashboard: [{ label: 'Dashboard', route: 'admin/dashboard' }],

  // Administrativo
  empresaConfiguracion: [
    { label: 'Administrativo', route: '' },
    { label: 'Archivo', route: '/admin/administativo/empresa-configuracion' },
    {
      label: 'Empresa Configuracion',
      route: '/admin/administativo/empresa-configuracion',
    },
  ],

  claveDinamica: [
    { label: 'Administrativo', route: '' },
    { label: 'Archivo', route: '/admin/administativo/clave-dinamica' },
    {
      label: 'Clave Dinamica',
      route: '/admin/administativo/clave-dinamica',
    },
  ],

  establecimientoComercial: [
    { label: 'Administrativo', route: '' },
    { label: 'Archivo', route: '/admin/administativo' },
    {
      label: 'Establecimiento Comercial',
      route: '/admin/administativo/establecimiento-comercial',
    },
  ],
  usuarios: [
    { label: 'Administrativo', route: '' },
    { label: 'Archivo', route: '/admin/administativo/usuarios' },
    { label: 'Usuarios', route: '/admin/administativo/usuarios' },
  ],
  permisosUsuarios: [
    { label: 'Administrativo', route: '' },
    { label: 'Archivo', route: '/admin/administracion' },
    {
      label: 'Permisos Usuarios',
      route: '/admin/administracion/permisos-usuarios',
    },
  ],

  // Contabilidad
  // Archivo
  conceptoDeclaracion: [
    { label: 'Contabilidad', route: '' },
    { label: 'Archivo', route: '/admin/contabilidad/concepto-declaracion' },
    {
      label: 'Concepto Declaracion',
      route: '/admin/contabilidad/concepto-declaracion',
    },
  ],
  impuestos: [
    { label: 'Contabilidad', route: '' },
    { label: 'Archivo', route: '/admin/contabilidad/impuestos' },
    { label: 'Impuestos', route: '/admin/contabilidad/impuestos' },
  ],
  basesTarifas: [
    { label: 'Contabilidad', route: '' },
    { label: 'Archivo', route: '/admin/contabilidad/bases-tarifas' },
    {
      label: 'Bases y Tarifas Tributarias',
      route: '/admin/contabilidad/bases-tarifas',
    },
  ],
  codigosContables: [
    { label: 'Contabilidad', route: '' },
    { label: 'Archivo', route: '/admin/contabilidad/codigos-contables' },
    {
      label: 'Codigos Contables',
      route: '/admin/contabilidad/codigos-contables',
    },
  ],
  centrosCostos: [
    { label: 'Contabilidad', route: '' },
    { label: 'Archivo', route: '/admin/contabilidad/centro-costo' },
    { label: 'Centros de Costos', route: '/admin/contabilidad/centro-costo' },
  ],
  subCentrosCostos: [
    { label: 'Contabilidad', route: '' },
    { label: 'Archivo', route: '/admin/contabilidad/subcentro-costo' },
    {
      label: 'Subcentros de Costos',
      route: '/admin/contabilidad/subcentros-costos',
    },
  ],
  fuenteContable: [
    { label: 'Contabilidad', route: '' },
    { label: 'Archivo', route: '/admin/contabilidad/fuentes-contables' },
    {
      label: 'Fuentes contables',
      route: '/admin/contabilidad/fuentes-contables',
    },
  ],
  consecutivos: [
    { label: 'Contabilidad', route: '' },
    { label: 'Archivo', route: '/admin/contabilidad/consecutivos' },
    { label: 'Consecutivos', route: '/admin/contabilidad/consecutivos' },
  ],
  terceros: [
    { label: 'Contabilidad', route: '' },
    { label: 'Archivo', route: '/admin/contabilidad/terceros' },
    { label: 'Terceros', route: '/admin/contabilidad/terceros' },
  ],
  anticipos: [
    { label: 'Contabilidad', route: '' },
    { label: 'Archivo', route: '/admin/contabilidad/anticipos-amortizables' },
    {
      label: 'Anticipos y Amortizables',
      route: '/admin/contabilidad/anticipos-amortizables',
    },
  ],
  periodosContables: [
    { label: 'Contabilidad', route: '' },
    { label: 'Archivo', route: '/admin/contabilidad/periodos-contables' },
    {
      label: 'Periodos Contables',
      route: '/admin/contabilidad/periodos-contables',
    },
  ],
  // Movimientos
  movimientosComprobanteDiario: [
    { label: 'Contabilidad', route: '' },
    {
      label: 'Movimientos',
      route: '/admin/contabilidad/movimientos/comprobante-diario',
    },
    {
      label: 'Comprobante Diario',
      route: '/admin/contabilidad/movimientos/comprobante-diario',
    },
  ],
  // Consultas
  consultaComprobanteDiario: [
    { label: 'Contabilidad', route: '' },
    {
      label: 'Consultas',
      route: '/admin/contabilidad/consultas/comprobante-diario',
    },
    {
      label: 'Comprobante Diario',
      route: '/admin/contabilidad/consultas/comprobante-diario',
    },
  ],
  // Reportes
  reporteLibroAuxiliar: [
    { label: 'Contabilidad', route: '' },
    {
      label: 'Reportes',
      route: '/admin/contabilidad/reportes/libro-auxiliar',
    },
    {
      label: 'Libro Auxiliar',
      route: '/admin/contabilidad/reportes/libro-auxiliar',
    },
  ],
  reporteBalancePrueba: [
    { label: 'Contabilidad', route: '' },
    {
      label: 'Reportes',
      route: '/admin/contabilidad/reportes/balance-prueba',
    },
    {
      label: 'Balance de prueba',
      route: '/admin/contabilidad/reportes/balance-prueba',
    },
  ],
  reporteCertificadoRetencion: [
    { label: 'Contabilidad', route: '' },
    {
      label: 'Reportes',
      route: '/admin/contabilidad/reportes/certificado-retencion',
    },
    {
      label: 'Certificados de retención',
      route: '/admin/contabilidad/reportes/certificado-retencion',
    },
  ],

  // Inventario
  // Archivo
  parametros_generales_inventario: [
    { label: 'Inventario', route: '' },
    {
      label: 'Archivo',
      route: '/admin/inventario/archivo/parametros-generales',
    },
    {
      label: 'Parametros Generales',
      route: '/admin/inventario/archivo/parametros-generales',
    },
  ],
  clases: [
    { label: 'Inventario', route: '' },
    {
      label: 'Archivo',
      route: '/admin/inventario/archivo/clases',
    },
    {
      label: 'Clases',
      route: '/admin/inventario/archivo/clases',
    },
  ],
  almacenes: [
    { label: 'Inventario', route: '' },
    {
      label: 'Archivo',
      route: '/admin/inventario/archivo/almacenes',
    },
    {
      label: 'Almacenes',
      route: '/admin/inventario/archivo/almacenes',
    },
  ],
  articulos: [
    { label: 'Inventario', route: '' },
    {
      label: 'Archivo',
      route: '/admin/inventario/archivo/articulos',
    },
    {
      label: 'Articulos',
      route: '/admin/inventario/archivo/articulos',
    },
  ],
  consecutivos_inventario: [
    { label: 'Inventario', route: '' },
    {
      label: 'Archivo',
      route: '/admin/inventario/archivo/consecutivos',
    },
    {
      label: 'Consecutivos',
      route: '/admin/inventario/archivo/consecutivos',
    },
  ],
  tipo_documento_inventario: [
    { label: 'Inventario', route: '' },
    {
      label: 'Archivo',
      route: '/admin/inventario/archivo/tipos-documentos',
    },
    {
      label: 'Tipo de Documento',
      route: '/admin/inventario/archivo/tipos-documentos',
    },
  ],
  prefijos_inventario: [
    { label: 'Inventario', route: '' },
    {
      label: 'Archivo',
      route: '/admin/inventario/archivo/prefijos',
    },
    {
      label: 'Prefijos',
      route: '/admin/inventario/archivo/prefijos',
    },
  ],
  tipo_interfaz_contable_inventario: [
    { label: 'Inventario', route: '' },
    {
      label: 'Archivo',
      route: '/admin/inventario/archivo/tipo-interfaz-contable',
    },
    {
      label: 'Tipo de Interfaz ',
      route: '/admin/inventario/archivo/tipo-interfaz-contable',
    },
  ],
  periodos_innventario: [
    { label: 'Inventario', route: '' },
    {
      label: 'Archivo',
      route: '/admin/inventario/archivo/periodos',
    },
    {
      label: 'Periodos',
      route: '/admin/inventario/archivo/periodos',
    },
  ],
  interfaz_contable_inventario: [
    { label: 'Inventario', route: '' },
    {
      label: 'Archivo',
      route: '/admin/inventario/archivo/interfaz-contable',
    },
    {
      label: 'Interfaz de Contabilidad',
      route: '/admin/inventario/archivo/interfaz-contable',
    },
  ],
  movimiento_inventario: [
    { label: 'Inventario', route: '' },
    {
      label: 'Movimientos',
      route: '/admin/inventario/movimientos/movimiento-inventario',
    },
    {
      label: 'Movimiento Inventario',
      route: '/admin/inventario/movimientos/movimiento-inventario',
    },
  ],
  movimiento_inventario_formulario: [
    { label: 'Inventario', route: '' },
    {
      label: 'Movimientos',
      route: '/admin/inventario/movimientos/movimiento-inventario',
    },
    { label: 'Formulario', route: '/admin/inventario/movimientos' },
  ],
  consulta_movimiento_inventario: [
    { label: 'Inventario', route: '' },
    {
      label: 'Movimientos',
      route: '/admin/inventario/movimientos/movimiento-inventario',
    },
    {
      label: 'Consulta Movimiento Inventario',
      route: '/admin/inventario/movimientos/movimiento-inventario',
    },
  ],

  // Facturación
  //Archivo
  parametrosGeneralesFacturacion: [
    { label: 'Facturación', route: '' },
    {
      label: 'Archivo',
      route: '/admin/facturacion/parametros-generales',
    },
    {
      label: 'Parametros Generales',
      route: '/admin/facturacion/parametros-generales',
    },
  ],
  asesoresVentas: [
    { label: 'Facturación', route: '' },
    {
      label: 'Archivo',
      route: '/admin/facturacion/asesores-de-ventas',
    },
    {
      label: 'Asesores de Ventas',
      route: '/admin/facturacion/asesores-de-ventas',
    },
  ],
  clientes: [
    { label: 'Facturación', route: '' },
    { label: 'Archivo', route: '/admin/facturacion/clientes' },
    {
      label: 'Clientes',
      route: '/admin/facturacion/clientes',
    },
  ],
  tipoInterfaz: [
    { label: 'Facturación', route: '' },
    { label: 'Archivo', route: '/admin/facturacion/tipo-interfaz' },
    {
      label: 'Tipo Interfaz Contable',
      route: '/admin/facturacion/tipo-interfaz',
    },
  ],
  interfazContable: [
    { label: 'Facturación', route: '' },
    { label: 'Archivo', route: '/admin/facturacion/interfaz-contable' },
    {
      label: 'Interfaz Contable',
      route: '/admin/facturacion/interfaz-contable',
    },
  ],
  tipoDocumento: [
    { label: 'Facturación', route: '' },
    { label: 'Archivo', route: '/admin/facturacion/tipo-documento' },
    { label: 'Tipo Documento', route: '/admin/facturacion/tipo-documento' },
  ],
  prefijosFacturacion: [
    { label: 'Facturación', route: '' },
    { label: 'Archivo', route: '/admin/facturacion/archivo/prefijos' },
    { label: 'Prefijos', route: '/admin/facturacion/archivo/prefijos' },
  ],
  consecutivosFacturacion: [
    { label: 'Facturación', route: '' },
    { label: 'Archivo', route: '/admin/facturacion/archivo/consecutivos' },
    { label: 'Consecutivos', route: '/admin/facturacion/archivo/consecutivos' },
  ],
  // Movimientos
  movimientosFacturacion: [
    { label: 'Facturación', route: '' },
    { label: 'Movimientos', route: '/admin/facturacion/movimientos/factura-venta' },
    { label: 'Factura de Venta', route: '/admin/facturacion/movimientos/factura-venta' },
  ],

  // Tesoreria
  // Archivo
  parametrosGeneralesTesoreria: [
    { label: 'Tesoreria', route: '' },
    {
      label: 'Archivo',
      route: '/admin/tesoreria/parametros-generales',
    },
    {
      label: 'Parametros Generales',
      route: '/admin/tesoreria/parametros-generales',
    },
  ],
  bancos: [
    { label: 'Tesoreria', route: '' },
    { label: 'Archivo', route: '/admin/tesoreria/archivo/bancos' },
    { label: 'Bancos', route: '/admin/tesoreria/archivo/bancos' },
  ],
  cuentasBancarias: [
    { label: 'Tesoreria', route: '' },
    { label: 'Archivo', route: '/admin/tesoreria/archivo/cuentas-bancarias' },
    {
      label: 'Cuentas Bancarias',
      route: '/admin/tesoreria/archivo/cuentas-bancarias',
    },
  ],
  mediosPago: [
    { label: 'Tesoreria', route: '' },
    { label: 'Archivo', route: '/admin/tesoreria/archivo/tipo-de-pago' },
    { label: 'Medios de Pago', route: '/admin/tesoreria/archivo/tipo-de-pago' },
  ],
  tipoInterfazContableTesoreria: [
    { label: 'Tesoreria', route: '' },
    {
      label: 'Archivo',
      route: '/admin/tesoreria/archivo/tipo-interfaz-contable',
    },
    {
      label: 'Tipo de Interfaz Contable',
      route: '/admin/tesoreria/archivo/tipo-interfaz-contable',
    },
  ],
  interfazContableTesoreria: [
    { label: 'Tesoreria', route: '' },
    { label: 'Archivo', route: '/admin/tesoreria/archivo/interfaz-contable' },
    {
      label: 'Interfaz Contable',
      route: '/admin/tesoreria/archivo/interfaz-contable',
    },
  ],
  tipoDocumentoTesoreria: [
    { label: 'Tesoreria', route: '' },
    { label: 'Archivo', route: '/admin/tesoreria/archivo/tipo-documento' },
    {
      label: 'Tipo Documento',
      route: '/admin/tesoreria/archivo/tipo-documento',
    },
  ],
  prefijosTesoreria: [
    { label: 'Tesoreria', route: '' },
    { label: 'Archivo', route: '/admin/tesoreria/archivo/prefijos' },
    { label: 'Prefijos', route: '/admin/tesoreria/archivo/prefijos' },
  ],
  consecutivosTesoreria: [
    { label: 'Tesoreria', route: '' },
    { label: 'Archivo', route: '/admin/tesoreria/archivo/consecutivos' },
    { label: 'Consecutivos', route: '/admin/tesoreria/archivo/consecutivos' },
  ],

  // Compraventa
  // Archivo
  prefijoClase: [
    { label: 'Compraventa', route: '' },
    { label: 'Archivo', route: '/admin/compraventa/prefijo-clase' },
    { label: 'Prefijo Clase', route: '/admin/compraventa/prefijo-clase' },
  ],
  compraVentaTipoInterfaz: [
    { label: 'Compraventa', route: '' },
    { label: 'Archivo', route: '/admin/compraventa/tipo-interfaz-contable' },
    {
      label: 'Tipo Interfaz Contable',
      route: '/admin/compraventa/tipo-interfaz-contable',
    },
  ],
  compraVentaInterfazContable: [
    { label: 'Compraventa', route: '' },
    { label: 'Archivo', route: '/admin/compraventa/interfaz-contable' },
    {
      label: 'Interfaz Contable',
      route: '/admin/compraventa/interfaz-contable',
    },
  ],
  compraVentaTipoDocumento: [
    { label: 'Compraventa', route: '' },
    { label: 'Archivo', route: '/admin/compraventa/tipo-documento' },
    {
      label: 'Tipo de Documento',
      route: '/admin/compraventa/compraventa-tipo-documento',
    },
  ],
  compraventaPrefijos: [
    { label: 'Compraventa', route: '' },
    { label: 'Archivo', route: '/admin/compraventa/compraventa-prefijo' },
    { label: 'Prefijos', route: '/admin/compraventa/compraventa-prefijo' },
  ],
  compraventaConsecutivos: [
    { label: 'Compraventa', route: '' },
    { label: 'Archivo', route: '/admin/compraventa/compraventa-consecutivos' },
    {
      label: 'Consecutivos',
      route: '/admin/compraventa/compraventa-consecutivos',
    },
  ],
  compraventaParametrosGenerales: [
    { label: 'Compraventa', route: '' },
    {
      label: 'Archivo',
      route: './admin/compraventa/compraventa-parametros-generales',
    },
    {
      label: 'Parámetros Generales',
      route: './admin/compraventa/compraventa-parametros-generales',
    },
  ],
  compraventaListadoSobrePrecio: [
    { label: 'Compraventa', route: '' },
    {
      label: 'Archivo',
      route: './admin/compraventa/compraventa-listado-sobre-precio',
    },
    {
      label: 'Listado Sobre Precio',
      route: './admin/compraventa/compraventa-listado-sobre-precio',
    },
  ],

  // Movimientos
  movimientosContratoInicial: [
    { label: 'Compraventa', route: '' },
    { label: 'Movimientos', route: '/admin/movimientos/contrato-inicial' },
    { label: 'Contrato Inicial', route: '/admin/movimientos/contrato-inicial' },
  ],
  movimientosContratoSaca: [
    { label: 'Compraventa', route: '' },
    { label: 'Movimientos', route: '/admin/movimientos' },
    { label: 'Contrato Saca', route: '/admin/movimientos/saca' },
  ],

  // Anulaciones
  anulacionesContratoInicial: [
    { label: 'Compraventa', route: '' },
    { label: 'Anulaciones', route: '/admin/movimientos/anulacion-contrato-inicial' },
    { label: 'Contrato Inicial', route: '/admin/movimientos/anulacion-contrato-inicial' },
  ],
  anulacionesContratoRenovacion: [
    { label: 'Compraventa', route: '' },
    { label: 'Anulaciones', route: '/admin/movimientos/anulacion-contrato-renovacion' },
    { label: 'Contrato Renovación', route: '/admin/movimientos/anulacion-contrato-renovacion' },
  ],
  anulacionesContratoRetiro: [
    { label: 'Compraventa', route: '' },
    { label: 'Anulaciones', route: '/admin/movimientos/anulacion-contrato-retiro' },
    { label: 'Contrato Retiro', route: '/admin/movimientos/anulacion-contrato-retiro' },
  ],

  // Consultas
  consultaContratoInicial: [
    { label: 'Compraventa', route: '' },
    { label: 'Consultas', route: '/admin/compraventa/consultas/contrato-inicial' },
    { label: 'Contrato Inicial', route: '/admin/compraventa/consultas/contrato-inicial' },
  ],

  // Reportes
  reporteContratoInicial: [
      { label: 'Compraventa', route: '' },
      { label: 'Reportes', route: '/admin/compraventa/reportes/contrato-inicial' },
      { label: 'Contrato Teórico', route: '/admin/compraventa/reportes/contrato-inicial' },
    ],
  reporteUtilidadContratos: [
      { label: 'Compraventa', route: '' },
      { label: 'Reportes', route: '/admin/compraventa/reportes/utilidad-contratos' },
      { label: 'Utilidad Contratos', route: '/admin/compraventa/reportes/utilidad-contratos' },
  ],
  reporteContratos: [ 
    { label: 'Compraventa', route: '' },
    { label: 'Reportes', route: '/admin/compraventa/reportes/contratos' },
    { label: 'Contratos', route: '/admin/compraventa/reportes/contratos' },
  ],


  // Comprasbs
  parametrosGenerales: [
    { label: 'Comprasbs', route: '' },
    { label: 'Archivo', route: '/admin/comprasbs/archivo/parametros-generales' },
    { label: 'Parametros Generales', route: '/admin/comprasbs/archivo/parametros-generales' },
  ],
  tipoInterfazComprabs: [
    { label: 'Comprasbs', route: '' },
    { label: 'Archivo', route: '/admin/comprasbs/archivo/tipo-interfaz-contable' },
    { label: 'Tipo de Interfaz Contable', route: '/admin/comprasbs/archivo/tipo-interfaz-contable' },
  ],
  interfazContableComprasbs: [
    { label: 'Comprasbs', route: '' },
    { label: 'Archivo', route: '/admin/comprasbs/archivo/interfaz-contable' },
    { label: 'Interfaz Contable', route: '/admin/comprasbs/archivo/interfaz-contable' },
  ],
  proveedores: [
    { label: 'Comprasbs', route: '' },
    { label: 'Archivo', route: '/admin/comprasbs/archivo/proveedores' },
    { label: 'Proveedores', route: '/admin/comprasbs/archivo/proveedores' },
  ],
};
