import { Injectable } from '@angular/core';
import {Subject} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class LayoutService {
  private themeChangeSubject = new Subject<string>();
  themeChange$ = this.themeChangeSubject.asObservable();

  constructor() {
    this.initializeStorageListener();
  }

  private initializeStorageListener(): void {
    window.addEventListener('storage', (event: StorageEvent) => {
      if (event.key === 'theme') {
        if (event.newValue != null) {
          this.themeChangeSubject.next(event.newValue);
        }
      }
    });
  }

  public setTheme(theme: string): void {
    this.themeChangeSubject.next(theme);
  }
}
