import { CommonModule } from '@angular/common';
import { Component, inject, Input, OnInit, signal } from '@angular/core';
import {
  ISidebar,
  ISidebarItem,
} from '@data/interfaces/components/sidebar.interface';
import { PrimengModule } from '@shared/primeng/primeng.module';
import { PermisosService } from '@core/services/api/permisos.service';
import {
  IChildren,
  IPermisosUsuarios,
} from '@data/interfaces/api/configuracion-empresa/permisos-usuario.interface';
import { orderConfig } from '@data/constants/orderConfig.constants';
import { iconsConfig } from '@data/constants/iconsConfig.constants';
import { SharedModule } from '@shared/shared.module';

@Component({
  selector: 'app-sidebar',
  standalone: true,
  imports: [CommonModule, PrimengModule, SharedModule],
  templateUrl: './sidebar.component.html',
})
export class SidebarComponent implements OnInit {
  @Input({ required: true }) options: ISidebar[] = [];
  private _permisosService = inject(PermisosService);
  private _orderConfig = orderConfig;

  public optionsSidebar = signal<ISidebar[]>([]);
  public sidebarVisible = true;
  public sidebarModal = false;

  ngOnInit(): void {
    this._permisosService.permisos$.subscribe((permisos) => {
      this.optionsSidebar.set(
        this.transformarPermisos(permisos, this._orderConfig),
      );
    });
  }

  private transformarPermisos(
    permisos: IPermisosUsuarios[],
    orderConfig: Record<string, Record<string, string[]>>,
  ): ISidebar[] {
    return permisos
      .map((module) => {
        const moduleOrder = orderConfig[module.nombre.toLowerCase()];

        const transformedModule: ISidebar = {
          title: module.nombre,
          permission: '',
          children: Object.entries(module.permisos)
            .map(([key, value]) => {
              const orderedItems = moduleOrder?.[this.capitalize(key)] || [];

              const transformedChildItems = value.children
                .filter((item: IChildren) => item.permiso === 1)
                .sort(
                  (a: any, b: any) =>
                    orderedItems.indexOf(a.nombre) -
                    orderedItems.indexOf(b.nombre),
                )
                .map((item: IChildren) => ({
                  title: item.nombre,
                  permission: item.slug,
                  icon: this.getIcons(item.nombre, module.nombre),
                  route: `${module.nombre}/${key}/${item.slug}`,
                }));

              if (transformedChildItems.length > 0) {
                return {
                  title: this.capitalize(key),
                  icon: 'pi-folder',
                  children: transformedChildItems,
                };
              }

              return null;
            })
            .filter((item) => item !== null) as ISidebarItem[],
        };

        if (
          !transformedModule.children ||
          transformedModule.children.length === 0
        ) {
          return null;
        }

        return transformedModule;
      })
      .filter((item) => item !== null) as ISidebar[];
  }

  private getIcons(item: string, moduleName: string): string | null {
    const moduleKey = moduleName.toLowerCase();

    const moduleConfig = iconsConfig[moduleKey as keyof typeof iconsConfig];

    if (moduleConfig) {
      for (const section of Object.keys(moduleConfig)) {
        const items = moduleConfig[section as keyof typeof moduleConfig];

        const foundItem = items.find(
          (iconItem: { nombre: string; icon: string }) =>
            iconItem.nombre.toLowerCase() === item.toLowerCase(),
        );

        if (foundItem) {
          return foundItem.icon.toLowerCase();
        }
      }
    }

    return null;
  }

  private capitalize(text: string): string {
    return text.charAt(0).toUpperCase() + text.slice(1);
  }
}
