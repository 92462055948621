import { patchState } from '@ngrx/signals';
import { Injectable } from '@angular/core';
import { DataService } from '@core/services/api/crud.service';
import { inject } from '@angular/core';
import { Observable, tap } from 'rxjs';
import { IResponse } from '@data/interfaces/api/response.interface';
import { IHttpOptions, IParamsQuery } from '@data/interfaces/components/http-options-interface';
import { HttpOptionsTools } from './http-options.tool';
import { HttpClient } from '@angular/common/http';
import { PaginacionResponse } from "@data/interfaces/api/pagination-response.interface";

interface FetchParams<T> {
  endpoint: string;
  store?: any;
  stateKey?: string;
  paginationKey?: string;
  updatekey?: keyof any;
  message?: string;
  query?: IParamsQuery;
}

@Injectable({
  providedIn: 'root', // O en un módulo si prefieres que no sea global
})
export class CrudToolService {
  private crudService = inject(DataService); // Inyectamos el servicio aquí
  private _http = inject(HttpClient);
  private _httpOptionTool = inject(HttpOptionsTools);

  fetchAll<T>(params: FetchParams<T>): void {
    const { endpoint, store, stateKey, message = 'Fetching data', query } = params;

    this.crudService.getAll({ endpoint, message, query }).subscribe({
      next: (res) => {
        if (res?.data && stateKey) {
          patchState(store, { [stateKey]: res.data, isLoading: true });
        }
      },
      error: (error) => {
        console.error('Error fetching data:', error);
        patchState(store, { isLoading: false });
      },
    });
  }

  fetchAllPagination<T>(params: FetchParams<PaginacionResponse<T>>): void {
    const { endpoint, store, stateKey, paginationKey, message = 'Fetching data' } = params;

    this.crudService.getAll({ endpoint, message }).subscribe({
      next: (res: any) => {
        if (res?.data && stateKey && paginationKey) {
          if (res?.data) {
            patchState(store, {
              [stateKey]: res.data,
              [paginationKey]: { ...res, data: undefined },
              isLoading: false,
            });
          }
        }
      },
      error: (error) => {
        console.error('Error fetching data:', error);
        patchState(store, { isLoading: false });
      },
    });
  }

  fetchOne<T>(params: FetchParams<T>): Observable<IResponse<T>> {
    const { endpoint, message = 'Fetching data' } = params;
    return this.crudService.getOne({ endpoint, message });
  }

  addItem<T>({
    endpoint,
    store,
    stateKey,
    data,
    message = 'Adding item',
  }: FetchParams<T> & { data: any }): void {
    this.crudService.create({ endpoint, data, message }).subscribe((res) => {
      if (res && stateKey) {
        patchState(store, {
          [stateKey]: [res.data, ...store[stateKey]()],
          isLoading: true,
        });
      }
    });
  }

  removeItem<T>({
    endpoint,
    store,
    stateKey,
    data,
    message = 'Removing item',
  }: FetchParams<T> & { data: any }): void {
    this.crudService.delete({ endpoint, data, message }).subscribe((res) => {
      if (res && stateKey) {
        patchState(store, {
          [stateKey]: store[stateKey]().filter((item: any) => item.codigo !== data.codigo),
          isLoading: true,
        });
      }
    });
  }

  addItemObservable<T>({
    endpoint,
    data,
    message = '',
  }: FetchParams<T> & { data: any }): Observable<IResponse<T>> {
    return this.crudService.create({ endpoint, data, message });
  }

  updateItem<T>({
    endpoint,
    store,
    stateKey,
    data,
    key,
    message = 'Updating item',
  }: FetchParams<T> & { data: any; key: keyof any }): void {
    this.crudService.update({ endpoint, data, message }).subscribe((res) => {
      if (res.data && stateKey) {
        patchState(store, {
          [stateKey]: store[stateKey]().map((item: any) =>
            item[key] === res.data[key] ? res.data : item,
          ),
          isLoading: true,
        });
      } else {
      }
    });
  }

  updateItemObservable<T>({
    endpoint,
    data,
    message = 'Updating item',
  }: FetchParams<T> & { data: any }): Observable<any> {
    return this.crudService.update({ endpoint, data, message });
  }

  getPdfBlob<T>({
    endpoint,
    data,
    message = 'Pdf',
  }: FetchParams<T> & { data: any }): Observable<Blob> {
    const httpOption: IHttpOptions = {
      path: endpoint,
      body: data,
    };

    const { url, body } = this._httpOptionTool.runSendData(httpOption);
    return this._http
      .post(url, body, { responseType: 'blob' })
      .pipe
      // tap((response: Blob) => {
      //   const url = window.URL.createObjectURL(response);
      //   this.setState(() => ({reporteComprobanteDiario: {url}, loadData: true}));
      // })
      ();
  }
}
