import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
  inject,
  signal,
} from '@angular/core';
import { Table } from 'primeng/table';
import { ConfirmationService } from 'primeng/api';
import { PrimengModule } from '@shared/primeng/primeng.module';
import { ITableColumn } from '@data/interfaces/components/table.interface';
import { IButton } from '@data/interfaces/components/header.interfaces';
import { ParseTablePipe } from '@shared/pipe/parse-table.pipe';
import { CommonModule } from '@angular/common';
import { TableTools } from '@shared/tools/table.tool';
import { DropdownChangeEvent } from 'primeng/dropdown';
import { TableComponentService } from '@core/services/components/table.service';
import { FormsModule } from '@angular/forms';
import { ILinks, IMeta } from "@data/interfaces/api/pagination-response.interface";

@Component({
  selector: 'app-table',
  standalone: true,
  imports: [PrimengModule, ParseTablePipe, CommonModule, FormsModule],
  templateUrl: './table.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./table.component.scss'],
})
export class TableComponent implements OnInit, AfterViewInit, OnChanges {
  @ViewChild('dt') dt: Table | null = null;
  @Input({ required: true }) columns: ITableColumn[] = [];
  @Input({ required: false }) dataRow: any[] = [];
  @Input({ required: false }) selectRow: boolean = true;
  @Input({ required: false }) selectAll: boolean = false;
  @Input({ required: false }) deleteRow: boolean = false;
  @Input({ required: false }) options: {
    name: string;
    link: string;
    icon: string;
  }[] = [];
  @Input({ required: false }) editRow: boolean = true;
  @Input({ required: false }) caption: boolean = true;
  @Input({ required: false }) pagination: boolean = true;
  @Input({ required: false }) arrowPagination: boolean = false;
  @Input({ required: false }) inputSearch: boolean = true;
  @Input({ required: false }) exportFileName: string = 'descarga';
  @Input({ required: false }) permissionEdit: string = '';
  @Input({ required: false }) permissionDelete: string = '';
  @Input({ required: false }) buttonTable: IButton[] = [];
  @Output() refreshTable = new EventEmitter<void>();
  @Output() getPageTable = new EventEmitter<string>();
  @Output() filterTable = new EventEmitter<{ value: any; }>();
  @Output() onDocument = new EventEmitter<void>();
  @Input({ required: false }) rowCount: number = 10;
  @Input({ required: false }) filterColum: boolean = false;
  @Input({ required: false }) store?: any;
  @Input({ required: false }) linksOptions!: ILinks;
  @Input({ required: false }) metaOptions!: IMeta;

  private _confirmationService = inject(ConfirmationService);
  public _tableService = inject(TableComponentService);
  public _tableTool = inject(TableTools);
  public tableScrollHeight: string = '400px';
  filters: Record<string, string> = {};

  ngOnInit(): void {
    this._tableService.checkScreenSize(window.innerWidth);
  }

  ngAfterViewInit() {
    this.focusInput();
    this.calcularAlturaTabla();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.calcularAlturaTabla();
    if (changes['dataRow'] && !changes['dataRow'].isFirstChange()) {
      this.calcularAlturaTabla();
    }

  }

  public applyFilterGlobal($event: Event) {
    const value = ($event.target as HTMLInputElement).value;
    this.dt?.filterGlobal(value, 'contains');
  }

  public edit(data: any): void {
    this._tableService.action = {
      type: 'EDIT',
      data,
    };
  }

  public orderByField({ value }: DropdownChangeEvent): void {
    this.dt?.sort({
      field: value,
      order: 2,
    });
  }

  public remove(data: any): void {
    this._confirmationService.confirm({
      key: 'confirm',
      message: 'Seguro que quieres realizar esta acción ?',
      header: 'Confirmar acción ',
      icon: 'pi pi-info-circle',
      acceptButtonStyleClass: 'p-button-danger p-button-text',
      rejectButtonStyleClass: 'p-button-text p-button-text',
      acceptIcon: 'none',
      acceptLabel: 'Si',
      rejectIcon: 'none',
      accept: () => {
        this._tableService.action = {
          type: 'REMOVE',
          data,
        };
      },
    });
  }

  public getOpcion(data: any, option: any): void {
    this._tableService.action = {
      type: 'OPCION',
      data: { ...data, ...option },
    };
  }

  // boton de busqueda new

  @ViewChild('searchInput') searchInput!: ElementRef<HTMLInputElement>;


  isSearchOpen = signal(false);
  searchTerm = signal('');

  toggleSearch() {
    this.isSearchOpen.update((v) => !v);
    if (this.isSearchOpen()) {
      setTimeout(() => this.focusInput(), 300);
    }
  }

  focusInput() {
    if (this.isSearchOpen() && this.searchInput) {
      this.searchInput.nativeElement.focus();
    }
  }

  onInputBlur() {
    if (!this.searchTerm()) {
      this.isSearchOpen.set(false);
    }
  }

  @HostListener('window:resize')
  calcularAlturaTabla() {
    const maxAltura = 600; // Altura máxima permitida
    const paginacionAltura = 50
    const tabla = document.querySelector('.table-container');

    if (tabla) {
      const alturaDisponible = tabla.clientHeight - paginacionAltura; // Espacio menos la paginación
      this.tableScrollHeight = `${Math.min(alturaDisponible, maxAltura - 20)}px`;


    }
  }

  isLastPage(): boolean {
    return this.linksOptions.next === null;
  }

  isFirstPage(): boolean {
    return this.linksOptions.prev === null;
  }

  onFilter(event: Event, field: string) {
    const inputElement = event.target as HTMLInputElement;
    this.filters[field] = inputElement.value.trim();
    this.filterTable.emit({ value: this.filters });
  }
}
