import { inject, Injectable } from '@angular/core';
import {
  IAsignarPermiso,
  IPermisosUsuarios,
} from '@data/interfaces/api/configuracion-empresa/permisos-usuario.interface';
import { StoreTool } from '@shared/tools/store.tool';
import { catchError, Observable, of, tap, throwError } from 'rxjs';
import { IResponse } from '@data/interfaces/api/response.interface';
import { IHttpOptions } from '@data/interfaces/components/http-options-interface';
import { HttpOptionsTools } from '@shared/tools/http-options.tool';
import { HttpClient } from '@angular/common/http';
import { MessageService } from 'primeng/api';

interface PermisosUsuariosState {
  permisosUsuarios: IPermisosUsuarios[];
  loadData: boolean;
}

const initialState: PermisosUsuariosState = {
  permisosUsuarios: [],
  loadData: false,
};

@Injectable({
  providedIn: 'root',
})
export class PermisosUsuariosService extends StoreTool<PermisosUsuariosState> {
  private _http = inject(HttpClient);
  private _httpOptionTool = inject(HttpOptionsTools);
  private _messageService = inject(MessageService);

  constructor() {
    super(initialState);
  }

  get permisosToLocalStorage(): IPermisosUsuarios[] {
    return localStorage.getItem('permisos')
      ? JSON.parse(localStorage.getItem('permisos') as string)
      : [];
  }

  set permisosToLocalStorage(items: IPermisosUsuarios[]) {
    localStorage.setItem('permisos', JSON.stringify(items));
  }

  get permisosUsuarios(): Observable<IPermisosUsuarios[]> {
    return of(this.permisosToLocalStorage);
  }

  set permisosUsuarios(items: IPermisosUsuarios[]) {
    this.permisosToLocalStorage = items;
    this.setState((state) => ({
      ...state,
      permisosUsuarios: items,
      loadData: true,
    }));
  }

  public listarPermisosUsuarios(): Observable<IResponse<IPermisosUsuarios[]>> {
    const httpOption: IHttpOptions = {
      path: 'configuracion/permisos',
    };

    const { url } = this._httpOptionTool.runSendData(httpOption);
    return this._http
      .get<IResponse<IPermisosUsuarios[]>>(url)
      .pipe(
        tap(({ data }) =>
          this.setState(() => ({ permisosUsuarios: data, loadData: true })),
        ),
      );
  }

  public listarPermisosUsuariosFormulario(
    empresa_id: number,
    usuario_id: number,
  ): Observable<IResponse<IPermisosUsuarios[]>> {
    const httpOption: IHttpOptions = {
      path: `configuracion/empresas/${empresa_id}/usuario/${usuario_id}/permisos`,
    };

    const { url } = this._httpOptionTool.runSendData(httpOption);
    return this._http
      .get<IResponse<IPermisosUsuarios[]>>(url)
      .pipe(
        tap(({ data }) =>
          this.setState(() => ({ permisosUsuarios: data, loadData: true })),
        ),
      );
  }

  public asignarPermiso(
    data: IAsignarPermiso,
  ): Observable<IResponse<IAsignarPermiso[]>> {
    const httpOption: IHttpOptions = {
      path: 'configuracion/empresas/usuario/permisos',
      body: data,
    };

    const { url, body } = this._httpOptionTool.runSendData(httpOption);
    return this._http.post<IResponse<IAsignarPermiso[]>>(url, body).pipe(
      tap(({ data }) =>
        this.setState((state) => ({
          permisosUsuarios: [...state.permisosUsuarios],
          loadData: true,
        })),
      ),
      tap(() => {
        this._messageService.add({
          key: 'toast',
          summary: 'Registro Creado!',
          detail: 'Permiso asignado con éxito!.',
          severity: 'success',
        });
      }),
      catchError((error) => throwError(() => error)),
    );
  }
}
