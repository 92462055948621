import { Injectable, signal } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

interface IFilter {
  value: string;
  type: string;
}
interface IAction {
  type: 'EDIT' | 'VIEW' | 'REMOVE' | 'DOCUMENT' | 'OPCION';
  data: any;
}

@Injectable({
  providedIn: 'root',
})
export class TableComponentService {
  private _filter = new BehaviorSubject<IFilter | null>(null);
  private _action = new Subject<IAction>();
  private scremSize = signal(window.innerWidth);
  private scrollHeigth = signal(this.scremSize() - 300);

  public selected!: any;

  public get selectedRows(): any[] {
    return this.selected;
  }

  public get $filter(): Observable<IFilter | null> {
    return this._filter.asObservable();
  }

  public set filter(value: IFilter) {
    this._filter.next(value);
  }

  public get $action(): Observable<IAction> {
    return this._action.asObservable();
  }

  public set action(value: IAction) {
    this._action.next(value);
  }

  public checkScreenSize(width: number): void {
    if (width <= 768) {
      this.scrollHeigth = signal(this.scremSize() - 300);
    }
  }

  public get scrollH(): string {
    return this.scrollHeigth().toString() + 'px';
  }

  public get tableHeight(): number {
    return this.scremSize() - 200;
  }
}
