import { Injectable, inject } from '@angular/core';
import { ModalEstablecimientoComercialComponent } from '@shared/components/modal-establecimiento-comercial/modal-establecimiento-comercial.component';
import { DialogService } from 'primeng/dynamicdialog';
import { BehaviorSubject, Observable, of } from "rxjs";

@Injectable({
  providedIn: 'root',
})
export class DataUsuarioService {
  private _dialogService = inject(DialogService);
  private mySubject = new BehaviorSubject<string>('');
  myObservable$ = this.mySubject.asObservable();

  constructor() { }

  set empresaCodigo(codigo: number) {
    localStorage.setItem('empresa_codigo', codigo ? codigo.toString() : '');
  }

  get empresaCodigo(): number {
    return Number(localStorage.getItem('empresa_codigo'));
  }

  get userCodigo(): number {
    return Number(localStorage.getItem('usuario_id'));
  }

  set establecimientoComercialCodigo(codigo: number) {
    localStorage.setItem('establecimiento_comercial_codigo', codigo ? codigo.toString() : '');
  }

  get establecimientoComercialCodigo(): number {
    return Number(localStorage.getItem('establecimiento_comercial_codigo'));
  }

  public checkEstablecimientoComercial(): Observable<boolean> {
    return this.establecimientoComercialCodigo
      ? of(true)
      : of(false);
  }

  public openModalEstablecimientoComercial(route: string): Observable<boolean> {
    const ref = this._dialogService.open(ModalEstablecimientoComercialComponent, {
      header: 'Establecimiento Comercial',
      width: '500px',
      closable: false,
      modal: true,
      focusOnShow: false,
      closeOnEscape: false,
      breakpoints: {
        '960px': '75vw',
        '640px': '90vw',
      },
      data: { route },
    });

    return of(false);
  }

  public setBehavior(newValue: string): void {
    this.mySubject.next(newValue);
  }

}
