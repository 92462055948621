import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class TableTools {
  public getNestedProperty(
    obj: any,
    path: string,
    defaultValue = undefined
  ): void {
    const keys = path.split('.');
    let result = obj;

    for (let key of keys) {
      if (result && typeof result === 'object' && key in result) {
        result = result[key];
      } else {
        return defaultValue;
      }
    }

    return result;
  }
}
