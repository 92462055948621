<div
  class="flex justify-between items-center px-3 border-b border-surface relative"
  style="height: 60px"
>
  <div class="flex max-h-screm w-full items-center">
    <a
      pRipple
      class="cursor-pointer inline-flex items-center justify-center text-color mr-3"
      pStyleClass="#app-multi-sidebar-1"
      enterFromClass="hidden"
      enterActiveClass="animate-fadeinleft"
      leaveToClass="hidden"
      leaveActiveClass="animate-fadeoutleft"
      [hideOnOutsideClick]="true"
    >
      <i class="pi pi-bars w-8 text-xl text-color"></i>
    </a>

    <div class="p-3 rounded flex flex-col justify-center align-content-center">
      <span class="text-xs sm:text-sm text-color font-medium">
        {{ nombre_empresa | uppercase }}
      </span>
    </div>
  </div>

  <div class="flex justify-center items-center gap-1">
    <div
      pRipple
      (click)="toogleTheme(!themeSelection)"
      class="p-2 cursor-pointer flex justify-center items-center rounded hover:surface-100 transition-duration-150 transition-colors p-ripple"
    >
      <i
        class="pi text-color text-center w-auto"
        [class]="themeSelection ? 'pi-sun' : 'pi-moon'"
        style="font-size: 1.3rem"
      ></i>
    </div>

    <div
      pRipple
      (click)="menuNotification.toggle($event)"
      class="p-2 flex items-center cursor-pointer rounded hover:surface-100 transition-duration-150 transition-colors p-ripple"
    >
      <p-menu
        #menuNotification
        [model]="notifications"
        [popup]="true"
        styleClass="w-full sm:w-60"
        [appendTo]="'body'"
      >
        <ng-template pTemplate="submenuheader" let-item>
          <span class="text-primary font-bold">{{ item.label }}</span>
          @if (!item.items.length) {
            <div class="w-full h-40 flex flex-col justify-center items-center">
              <p-avatar
                class="rounded-md mb-3"
                pRipple
                size="normal"
                icon="pi pi-bell"
              />
              <div class="text-center flex flex-col">
                <span class="text-lg text-color font-semibold"
                  >Sin notificaciones</span
                >
                <span class="text-sm font-normal text-surface-500 px-4 mt-2"
                  >Cuando tenga notificaciones, aparecerán aquí.</span
                >
              </div>
            </div>
          }
        </ng-template>
        <ng-template pTemplate="item" let-item>
          <a pRipple class="flex items-start p-menuitem-link">
            <div class="pr-3">
              <span class="mt-1" [class]="item.icon"></span>
            </div>
            <div class="flex flex-col">
              <span class="font-semibold text-sm">{{ item.label }}</span>
              <span class="text-color text-sm mt-1">{{ item.title }}</span>
              <span class="text-surface-500 text-xs mt-1">{{
                "Jun 05, 5:26 PM"
              }}</span>
            </div>
          </a>
        </ng-template>
      </p-menu>
      <i
        class="pi pi-bell text-color"
        pBadge
        style="font-size: 1.3rem"
        severity="danger"
      ></i>
    </div>

    <p-divider layout="vertical"></p-divider>

    <div>
      <p-menu
        #menu
        [model]="items"
        [popup]="true"
        styleClass="w-full sm:w-60 "
      />
      <p-avatar
        class="cursor-pointer"
        pRipple
        size="normal"
        icon="pi pi-user"
        (click)="menu.toggle($event)"
      />
    </div>
  </div>
</div>
