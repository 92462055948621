<ul class="list-none p-3 m-0 flex items-center font-medium min-w-max">
  <li class="pr-3">
    <a class="cursor-pointer">
      <i class="pi pi-home text-blue-500"></i>
    </a>
  </li>

  @for (item of config; track $index) {
  <li class="px-2">
    <i class="pi pi-angle-right text-surface-500"></i>
  </li>
  <li class="px-2">
    <a [routerLink]="[item.route]" class="cursor-pointer white-space-nowrap text-xs md:text-sm" [ngClass]="{
          'text-color': config.length === $index + 1,
          'text-blue-500 ': config.length !== $index + 1,
        }">
      {{ item.label }}
    </a>
  </li>
  }
</ul>
