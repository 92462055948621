export const iconsConfig = {
  administrativo: {
    Archivo: [
      {
        nombre: 'Empresa Configuración',
        icon: 'pi pi-file',
      },
      {
        nombre: 'Establecimiento Comercial',
        icon: 'pi pi-file',
      },
      {
        nombre: 'Usuarios',
        icon: 'pi pi-file',
      },
      {
        nombre: 'Permisos Usuarios',
        icon: 'pi pi-file',
      },
      {
        nombre: 'Clave Dinamica',
        icon: 'pi pi-qrcode',
      }
    ],
  },
  contabilidad: {
    Archivo: [
      {
        nombre: 'Conceptos Declaraciones',
        icon: 'pi pi-receipt',
      },
      {
        nombre: 'Impuestos',
        icon: 'pi pi-dollar',
      },
      {
        nombre: 'Bases y Tarifas Tributarias',
        icon: 'pi pi-percentage',
      },
      {
        nombre: 'Codigos Contables',
        icon: 'pi pi-sort',
      },
      {
        nombre: 'Centros de Costos',
        icon: 'pi pi-objects-column',
      },
      {
        nombre: 'Subcentros de Costos',
        icon: 'pi pi-objects-column',
      },
      {
        nombre: 'Fuentes Contables',
        icon: 'pi pi-book',
      },
      {
        nombre: 'Consecutivos',
        icon: 'pi pi-angle-double-up',
      },
      {
        nombre: 'Terceros',
        icon: 'pi pi-id-card',
      },
      {
        nombre: 'Periodos Contables',
        icon: 'pi pi-calendar',
      },
      {
        nombre: 'Anticipos Amortizables',
        icon: 'pi pi-wave-pulse',
      },
    ],
    Movimientos: [
      {
        nombre: 'Comprobante diario',
        icon: 'pi pi-clipboard',
      },
    ],
    Consultas: [
      {
        nombre: 'Comprobante diario',
        icon: 'pi pi-clipboard',
      },
    ],
    Reportes: [
      {
        nombre: 'Libro auxiliar',
        icon: 'pi pi-file',
      }
    ],
  },
  inventario: {
    Archivo: [
      {
        nombre: 'Interfaz contable',
        icon: 'pi pi-calculator',
      },
      {
        nombre: 'Periodos',
        icon: 'pi pi-calendar-clock',
      },
      {
        nombre: 'Prefijos',
        icon: 'pi pi-plus-circle',
      },
      {
        nombre: 'Tipo interfaz contable',
        icon: 'pi pi-book',
      },
      {
        nombre: 'Tipo documento',
        icon: 'pi pi-tag',
      },
      {
        nombre: 'Parametros generales',
        icon: 'pi pi-sliders-h',
      },
      {
        nombre: 'Almacenes',
        icon: 'pi pi-shop',
      },
      {
        nombre: 'Clases',
        icon: 'pi pi-list-check',
      },
      { nombre: 'Consecutivos', icon: 'pi pi-angle-double-up' },
      { nombre: 'Articulos', icon: 'pi pi-shopping-bag' },
    ],
    Movimientos: [
      {
        nombre: 'Movimiento inventario',
        icon: 'pi pi-shopping-cart',
      },
    ],
    Consultas: [
      {
        nombre: 'Movimientos inventario',
        icon: 'pi pi-shopping-cart',
      },
    ],
    Reportes: [],
  },
  facturacion: {
    Archivo: [
      {
        nombre: 'Parametros Generales',
        icon: 'pi pi-sliders-h',
      },
      {
        nombre: 'Asesores de Ventas',
        icon: 'pi pi-briefcase',
      },
      {
        nombre: 'Clientes',
        icon: 'pi pi-shopping-cart',
      },
      {
        nombre: 'Tipo interfaz contable',
        icon: 'pi pi-chart-bar',
      },
      {
        nombre: 'Interfaz contable',
        icon: 'pi pi-check-circle',
      },
      {
        nombre: 'Tipo documento',
        icon: 'pi pi-tag',
      },
      {
        nombre: 'Prefijos',
        icon: 'pi pi-plus-circle',
      },
      {
        nombre: 'Consecutivos',
        icon: 'pi pi-angle-double-up',
      },
    ],
    Movimientos: [
      {
        nombre: 'Factura venta',
        icon: 'pi pi-file',
      },
      {
        nombre: 'Notas',
        icon: 'fa fa-file-invoice'
      }
    ],
    Consultas: [],
    Reportes: [],
  },
  tesoreria: {
    Archivo: [
      {
        nombre: 'Parametros Generales',
        icon: 'pi pi-sliders-h',
      },
      {
        nombre: 'Bancos',
        icon: 'pi pi-building-columns',
      },
      {
        nombre: 'Cuentas Bancarias',
        icon: 'pi pi-credit-card',
      },
      {
        nombre: 'Medios de pagos',
        icon: 'pi pi-wallet',
      },
      {
        nombre: 'Tipo interfaz contable',
        icon: 'pi pi-book',
      },
      {
        nombre: 'Interfaz contable',
        icon: 'pi pi-calculator',
      },
      {
        nombre: 'Tipo documento',
        icon: 'pi pi-tag',
      },
      {
        nombre: 'Prefijos',
        icon: 'pi pi-plus-circle',
      },
      {
        nombre: 'Consecutivos',
        icon: 'pi pi-angle-double-up',
      },
    ],
    Movimientos: [],
    Consultas: [],
    Reportes: [],
  },
  compraventa: {
    Archivo: [
      {
        nombre: 'Prefijo Clase',
        icon: 'pi pi-plus-circle',
      },
      {
        nombre: 'Tipo interfaz contable',
        icon: 'pi pi-chart-bar',
      },
      {
        nombre: 'Interfaz contable',
        icon: 'pi pi-check-circle',
      },
      {
        nombre: 'Tipo documento',
        icon: 'pi pi-tag',
      },
      {
        nombre: 'Prefijos',
        icon: 'pi pi-plus-circle',
      },
      {
        nombre: 'Consecutivos',
        icon: 'pi pi-angle-double-up',
      },
      {
        nombre: 'Parametros generales',
        icon: 'pi pi-sliders-h',
      },
      {
        nombre: 'Listado Sobre Precio',
        icon: 'pi pi-list-check',
      },
    ],
    Movimientos: [
      {
        nombre: 'Contrato inicial',
        icon: 'pi pi-file-edit',
      },
      {
        nombre: 'Anulacion contrato inicial',
        icon: 'pi pi-ban',
      },
      {
        nombre: 'Retiro',
        icon: 'fa-solid fa-file-arrow-down fa-lg'
      },
      {
        nombre: 'Anulacion contrato renovacion',
        icon: 'pi pi-ban',
      },
      {
        nombre: 'Anulacion contrato retiro',
        icon: 'pi pi-ban',
      },
      {
        nombre: 'Saca',
        icon: 'fa-solid fa-repeat'
      }
    ],
    Consultas: [],
    Reportes: [
      {
        nombre: 'Contrato teorico',
        icon: 'pi pi-file-pdf',
      },
      {
        nombre: 'Utilidad contratos',
        icon: 'pi pi-file-pdf',
      },
      {
        nombre: 'Contratos',
        icon: 'pi pi-file-pdf',
      },
    ],
  },
  comprasbs: {
    Archivo: [
      {
        nombre: 'Parametros generales',
        icon: 'pi pi-sliders-h',
      },
      {
        nombre: 'Tipo interfaz contable',
        icon: 'pi pi-chart-bar',
      },
      {
        nombre: 'Interfaz contable',
        icon: 'pi pi-check-circle',
      },
      {
        nombre: 'Proveedores',
        icon: 'pi pi-user',
      }
    ],
    Movimientos: [],
    Consultas: [],
    Reportes: [],
  },
};

export const MODULES_ICONS = {
  administrativo: 'pi pi-cog',
  contabilidad: 'pi pi-chart-bar',
  facturacion: 'pi pi-receipt',
  inventario: 'pi pi-box',
  tesoreria: 'pi pi-money-bill',
  compras: 'pi pi-shopping-cart',
  proveedores: 'pi pi-address-book',
  compraventa: 'pi pi-shopping-cart',
  comprasbs: 'pi pi-folder',
};
