import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { IHttpOptions } from '../../data/interfaces/components/http-options-interface';

// Define que `IParamsQ` pueda ser un objeto con propiedades que son string, número o array de números.
export interface IParamsQ {
  [key: string]: string | number | boolean | Array<string | number | boolean>;
}

export const API_DB: Record<number, string> = {
  1: environment.hostname,
  2: environment.API_ELECTRONIC,
};

@Injectable({
  providedIn: 'root',
})
export class HttpOptionsTools {
  public runSendData(
    { param, path, body, query, file }: IHttpOptions,
    api_id?: number,
  ) {
    let formData = new FormData();
    let paramsQuery = new HttpParams();

    const host = API_DB[api_id || 1];
    const url: string = param ? `${host}/${path}/${param}` : `${host}/${path}`;

    if (query) {
      Object.entries(query).forEach(([key, value]) => {
        if (Array.isArray(value)) {
          value.forEach((v) => {
            paramsQuery = paramsQuery.append(`${key}[]`, v.toString());
          });
        } else {
          paramsQuery = paramsQuery.append(key, value as any);
        }
      });
    }

    if (file) {
      formData.append(`file`, file);
    }

    return {
      url,
      body,
      query: paramsQuery,
      formData,
    };
  }
}
