import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { HeaderComponent } from './components/header/header.component';
import { TableComponent } from './components/table/table.component';
import { ParseTitlePipe } from './pipe/parse-title.pipe';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { ToolbarComponent } from './components/toolbar/toolbar.component';
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HeaderComponent,
    TableComponent,
    ParseTitlePipe,
    SidebarComponent,
    HeaderComponent,
    ToolbarComponent,
    BreadcrumbComponent
  ],
  exports:[
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    HeaderComponent,
    TableComponent,
    ParseTitlePipe,
    SidebarComponent,
    HeaderComponent,
    ToolbarComponent,
    BreadcrumbComponent
  ]
})
export class SharedModule { }
