
import { inject } from '@angular/core';
import {
  patchState,
  signalStore,
  withHooks,
  withMethods,
  withState,
} from '@ngrx/signals';
import { DataUsuarioService } from '../data-usuario.service';
import {
  CommercialStablihment,
  CommercialStablihmentRequest,
} from '@data/models/administrative.models';
import {CrudToolService} from "@shared/tools/generic-crud.tool";

export type commercialStablihmentState = {
  isLoading: boolean;
  commercialStablihment: CommercialStablihment[];
};

const initialState: commercialStablihmentState = {
  isLoading: false,
  commercialStablihment: [],
};

const endpoints = {
  getCommercialStablihment: (empresa_codigo: number): string =>
    `administrativo/empresas/${empresa_codigo}/establecimientos-comerciales`,
  addCommercialStablihment: 'administrativo/establecimientos-comerciales',
  editCommercialStablihment: 'administrativo/establecimientos-comerciales',
};

export const commercialStablihmentStore = signalStore(
  { providedIn: 'root' },
  withState(initialState),
  withMethods(
    (
      store,
      userData = inject(DataUsuarioService),
      crudToolService = inject(CrudToolService),
    ) => ({
      getCommercialStablihment(): void {
        crudToolService.fetchAll({
          endpoint: endpoints.getCommercialStablihment(userData.empresaCodigo),
          store,
          stateKey: 'commercialStablihment',
          message: 'Establecimiento Comercial',
        });
      },
      add(stablihment: CommercialStablihmentRequest): void {
        crudToolService.addItem({
          endpoint: endpoints.addCommercialStablihment,
          store,
          stateKey: 'commercialStablihment',
          data: stablihment,
          message: 'Establecimiento Comercial',
        });
      },
      edit(stablihment: CommercialStablihmentRequest): void {
        crudToolService.updateItem({
          endpoint: endpoints.editCommercialStablihment,
          store,
          stateKey: 'commercialStablihment',
          data: stablihment,
          key: 'codigo',
          message: 'Establecimiento Comercial',
        });
      },
    }),
  ),
  withHooks({
    onInit: (store) => {
      store.getCommercialStablihment();
    },
  }),
);

