import { inject, Injectable } from '@angular/core';
import {ICrearEmpresa, IEmpresas} from '@data/interfaces/api/empresas.interface';
import { StoreTool } from '@shared/tools/store.tool';
import { HttpClient } from '@angular/common/http';
import { HttpOptionsTools } from '@shared/tools/http-options.tool';
import {catchError, concatMap, Observable, of, tap, throwError} from 'rxjs';
import { IHttpOptions } from '@data/interfaces/components/http-options-interface';
import { IResponse } from '@data/interfaces/api/response.interface';
import {MessageService} from "primeng/api";
import {Router} from "@angular/router";

interface EmpresasState {
  empresas: Array<IEmpresas>;
  loadData: boolean;
}

const initialState: EmpresasState = {
  empresas: [],
  loadData: false,
};

@Injectable({
  providedIn: 'root',
})
export class EmpresasService extends StoreTool<EmpresasState> {
  private _http = inject(HttpClient);
  private _httpOptionTool = inject(HttpOptionsTools);
  private _messageService = inject(MessageService);

  constructor() {
    super(initialState);
  }

  public getListarEmpresas(usuario_id: number): Observable<IResponse<IEmpresas[]>> {
    const httpOption: IHttpOptions = {
      path: `configuracion/usuarios/${usuario_id}/empresas`,
    };

    const { url } = this._httpOptionTool.runSendData(httpOption);
    return this._http
      .get<IResponse<IEmpresas[]>>(url)
      .pipe(
        tap(({ data }) =>
          this.setState(() => ({ empresas: data, loadData: true })),
        ),
      );
  }

  public getListarUsuariosEmpresas(empresa_codigo: number): Observable<IResponse<IEmpresas[]>> {
    const httpOption: IHttpOptions = {
      path: `administrativo/empresas/${empresa_codigo}/usuarios`,
    };

    const { url } = this._httpOptionTool.runSendData(httpOption);
    return this._http
      .get<IResponse<IEmpresas[]>>(url)
      .pipe(
        tap(({ data }) =>
          this.setState(() => ({ empresas: data, loadData: true })),
        ),
      );
  }

  public crearEmpresa(
    data: ICrearEmpresa
  ): Observable<IResponse<ICrearEmpresa>>  {
    const httpOption: IHttpOptions = {
      path: 'configuracion/empresas',
      body: data,
    };

    const { url, body } = this._httpOptionTool.runSendData(httpOption);
    return this._http.post<IResponse<ICrearEmpresa>>(url, body).pipe(
      tap(() =>
        this.setState((state) => ({
          empresas: [...state.empresas],
          loadData: true,
        }))
      ),
      concatMap((response) => {
        this._messageService.add({
          key: 'toast',
          summary: 'Registro Creado!',
          detail: 'Empresa creada con éxito!.',
          severity: 'success',
        });

        return of(response);
      }),
      catchError((error) => throwError(() => {
        this.setState((state) => ({
          empresas: [],
          loadData: true,
        }))
        this._messageService.add({
          key: 'toast',
          summary: 'Error en la creación de empresa',
          detail: 'No se pudo crear la empresa',
          severity: 'error'
        })
      return  error}))
    )
  }

  public deleteEmpresa(){
    this.setState(() => ({
      empresas: [],
      loadData: false,
    }))
  }
}
