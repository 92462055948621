export const orderConfig = {
  administrativo: {
    Archivo: [
      'Empresa Configuracion',
      'Establecimiento Comercial',
      'Usuarios',
      'Permisos Usuarios',
      'Clave Dinamica'
    ],
  },
  contabilidad: {
    Archivo: [
      'Conceptos Declaraciones',
      'Impuestos',
      'Bases y Tarifas Tributarias',
      'Codigos Contables',
      'Centros de Costos',
      'Subcentros de Costos',
      'Fuentes Contables',
      'Consecutivos',
      'Terceros',
      'Periodos Contables',
      'Anticipos Amortizables',
    ],
    Movimientos: ['comprobante diario'],
    Consultas: ['Comprobante Diario'],
    Reportes: [
      'Libro Auxiliar',
      'Balance de prueba',
      'Certificados de retencion',
    ],
  },
  facturacion: {
    Archivo: [
      'Parametros Generales',
      'Asesores de Ventas',
      'Clientes',
      'Tipo interfaz contable',
      'Interfaz contable',
      'Tipo documento',
      'Prefijos',
      'Consecutivos',
    ],
    Movimientos: [
      'Factura venta',
      'Notas'
    ],
    Consultas: [],
    Reportes: [],
  },
  inventario: {
    Archivo: [
      'Parametros Generales',
      'Almacenes',
      'Clases',
      'Articulos',
      'Tipo interfaz contable',
      'Interfaz contable',
      'Tipo documento',
      'Prefijos',
      'Consecutivos',
    ],
    Movimientos: [],
    Consultas: [],
    Reportes: [],
  },
  tesoreria: {
    Archivo: [
      'Parametros Generales',
      'Bancos',
      'Cuentas Bancarias',
      'Medios de pagos',
      'Tipo interfaz contable',
      'Interfaz contable',
      'Tipo documento',
      'Prefijos',
      'Consecutivos',
    ],
    Movimientos: [],
    Consultas: [],
    Reportes: [],
  },
  compraventa: {
    Archivo: [
      'Prefijo Clase',
      'Tipo interfaz contable',
      'Interfaz contable',
      'Tipo documento',
      'Prefijos',
      'Consecutivos',
      'Parametros generales',
      'Listado Sobre Precio',
    ],
    Movimientos: [
      'Contrato Inicial',
      'Retiro',
      'Saca',
      'Anulacion contrato inicial',
      'Anulacion contrato renovacion',
      'Anulacion contrato retiro',
    ],
    Consultas: [],
    Reportes: [
      'Contrato Teorico',
      'Utilidad Contratos',
      'Contratos'
    ],
  },
  comprasbs: {
    Archivo: [
      'Parametros Generales',
      'Tipo interfaz contable',
      'Interfaz contable',
      'Proveedores',
    ],
  },
};
