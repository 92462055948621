import { CommonModule, DOCUMENT } from '@angular/common';
import {AfterViewInit, Component, Inject, inject, Input, OnInit} from '@angular/core';
import { AuthService } from '@core/services/api/auth.service';
import { PrimengModule } from '@shared/primeng/primeng.module';
import { ConfirmationService, MenuItem } from 'primeng/api';
import {LayoutService} from "@core/services/api/layout.service";

@Component({
  selector: 'app-toolbar',
  standalone: true,
  imports: [CommonModule, PrimengModule],
  templateUrl: './toolbar.component.html',
})
export class ToolbarComponent implements OnInit, AfterViewInit {
  @Input({ required: false }) nombre_empresa: string = '';
  private _confirmationService = inject(ConfirmationService);
  private _authService = inject(AuthService);
  private _layoutService = inject(LayoutService);

  public showSidebar = true;
  public themeSelection: boolean = false;

  public notifications: MenuItem[] = [
    {
      label: 'Notificaciones',
      items: [],
    },
  ];

  public items: MenuItem[] = [
    {
      label: 'Perfil',
      items: [
        {
          label: '',
          badge: '',
          icon: 'pi pi-user',
        },
        {
          label: '',
          icon: '',
        },
      ],
    },
    {
      label: '',
      items: [
        {
          label: 'Cerrar sesión',
          icon: 'pi pi-power-off',
          command: () => {
            this.logOut();
          },
          styleClass: 'border-top-1 border-200',
        },
      ],
    },
  ];

  constructor(@Inject(DOCUMENT) private document: Document) {
    let theme = window.localStorage.getItem('theme');

    if (theme) {
      this.themeSelection = theme == 'dark' ? true : false;
      this.toogleTheme(this.themeSelection);
    } else {
      window.localStorage.setItem('theme', '');
      this.themeSelection = false;
      this.toogleTheme(this.themeSelection);
    }
  }

  ngOnInit(): void {
    this._authService
      .selectState(({ user }) => ({ user }))
      .subscribe(({ user }) => {
        const [profile] = this.items;
        (profile as any).items[0].label = user?.nombre;
        (profile as any).items[0].badge = 'Admin';
        (profile as any).items[1].label = user?.correo;
      });
  }

  ngAfterViewInit(): void {
    this.updateTableTheme(localStorage.getItem('theme') === 'dark' ? true : false);
    this.toogleTheme(localStorage.getItem('theme') === 'dark')
  }

  toogleTheme(state: boolean) {
    this.themeSelection = state;
    let theme = state ? 'dark' : '';

    if (this.themeSelection) {
      this._layoutService.setTheme(theme);
      localStorage.setItem('theme', 'dark');
      this.document.querySelector('html')?.classList.add('dark');
      this.updateTableTheme(true);
    } else {
      this._layoutService.setTheme('');
      localStorage.setItem('theme', '');
      this.document.querySelector('html')?.classList.remove('dark');
      this.updateTableTheme(false);
    }
  }

  updateTableTheme(isDark: boolean) {
    const tableContainer = this.document.querySelector('.p-datatable-table-container') as HTMLElement;

    if (tableContainer) {
      tableContainer.style.backgroundColor = isDark ? '#18181b' : '#ffffff';
    }
  }

  public logOut(): void {
    this._confirmationService.confirm({
      key: 'confirm',
      message: 'Seguro que quieres cerrar la sesión?',
      header: 'Cerrar sesión',
      icon: 'pi pi-info-circle',
      acceptButtonStyleClass: 'p-button-danger p-button-text',
      rejectButtonStyleClass: 'p-button-text p-button-text',
      acceptIcon: 'none',
      acceptLabel: 'Si',
      rejectIcon: 'none',
      accept: () => {
        this._authService.logout();
        window.location.reload();
      },
    });
  }
}
