<div class="w-full">
  <div class="w-full flex gap-2 justify-between">
    <div class="w-full flex flex-col gap-2 p-4">
      <label class="text-sm font-semibold text-color">Seleccionar</label>
      <p-select [options]="establecimientoComercial$.commercialStablihment()" [(ngModel)]="establecimiento"
        optionValue="codigo" optionLabel="nombre" filterBy="nombre" [checkmark]="true" [filter]="true"
        appendTo="body" />

      <p-button (onClick)="onSave()" label="Guardar" size="small" />
    </div>
  </div>
</div>
