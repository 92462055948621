import { Component, OnInit, inject } from '@angular/core';
import { PrimengModule } from "@shared/primeng/primeng.module";
import { SharedModule } from "@shared/shared.module";
import { commercialStablihmentStore } from "@core/services/api/configuracion-empresa/commercial-stablishment.store";
import { DataUsuarioService } from "@core/services/api/data-usuario.service";
import { DynamicDialogConfig, DynamicDialogRef } from "primeng/dynamicdialog";
import { Router } from "@angular/router";
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'app-modal-establecimiento-comercial',
  standalone: true,
  imports: [
    PrimengModule,
    FormsModule,
    SharedModule,
  ],
  templateUrl: './modal-establecimiento-comercial.component.html'
})
export class ModalEstablecimientoComercialComponent implements OnInit {
  private _refDialog = inject(DynamicDialogRef);
  private _dialogData = inject(DynamicDialogConfig);
  private _dataUsuarioService = inject(DataUsuarioService);
  private _router = inject(Router);
  establecimientoComercial$ = inject(commercialStablihmentStore)
  establecimiento: number | null = null;

  public dataUsuarioService = inject(DataUsuarioService);

  ngOnInit(): void {
    this.establecimiento = this.dataUsuarioService.establecimientoComercialCodigo ?? null;
  }

  onSave() {
    if (!this.establecimiento) return
    this.dataUsuarioService.establecimientoComercialCodigo = this.establecimiento;
    this._dataUsuarioService.setBehavior(this.establecimiento.toString());
    this._refDialog.close();
    this._router.navigateByUrl(this._dialogData.data?.route);
  }

}
