// Angular
import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
// Primeblosk
import { MessageService } from 'primeng/api';
import { catchError, Observable, of, tap, throwError } from 'rxjs';
// Store
import {
  IHttpOptions,
  IParamsQuery,
} from '@data/interfaces/components/http-options-interface';
import { IResponse } from '@data/interfaces/api/response.interface';

import { HttpOptionsTools } from '@shared/tools/http-options.tool';

@Injectable({
  providedIn: 'root',
})
export class DataService<T> {
  protected _http = inject(HttpClient);
  protected _httpOptionTool = inject(HttpOptionsTools);
  protected _messageService = inject(MessageService);

  constructor() { }

  public getAll(
    {
      endpoint,
      message,
      query,
    }: {
      endpoint: string;
      message: string;
      query?: IParamsQuery;
    },
    api_id?: number,
  ): Observable<IResponse<T[]>> {
    const httpOption: IHttpOptions = {
      path: endpoint,
      query,
    };

    const { url, query: paramsQuery } = this._httpOptionTool.runSendData(
      httpOption,
      api_id,
    );

    return this._http
      .get<IResponse<T[]>>(url, { params: paramsQuery })
      .pipe(
        catchError((error) => this.handleError<IResponse<T[]>>(message, error)),
      );
  }

  public getOne({
    endpoint,
    message,
    query,
  }: {
    endpoint: string;
    message: string;
    query?: IParamsQuery;
  }): Observable<IResponse<T>> {
    const httpOption: IHttpOptions = {
      path: endpoint,
      query,
    };

    const { url, query: paramsQuery } =
      this._httpOptionTool.runSendData(httpOption);
    return this._http
      .get<IResponse<T>>(url, { params: paramsQuery })
      .pipe(
        catchError((error) => this.handleError<IResponse<T>>(message, error)),
      );
  }

  public create({
    data,
    endpoint,
    message,
  }: {
    data: T;
    endpoint: string;
    message: string;
  }): Observable<IResponse<T>> {
    const httpOption: IHttpOptions = {
      path: endpoint,
      body: data,
    };
    const { url, body } = this._httpOptionTool.runSendData(httpOption);
    return this._http.post<IResponse<T>>(url, body).pipe(
      catchError((error) => this.handleError<IResponse<T>>(message, error)),
      tap((res) => {
        if (res.data && message) {
          this.handleSuccess(message);
        } else if (res.data && message === '') {
          this.handleSuccess(message);
        }
      }),
    );
  }

  public delete({ data, endpoint, message }: {
    data: T;
    endpoint: string;
    message: string;
  }): Observable<IResponse<T>> {
    const httpOption: IHttpOptions = {
      path: endpoint,
      body: data,
    };
    const { url, body } = this._httpOptionTool.runSendData(httpOption);
    return this._http.post<IResponse<T>>(url, body).pipe(
      catchError((error) => this.handleError<IResponse<T>>(message, error)),
      tap((res) => {
        if (res.data) {
          this.handleDelete(message);
        }
      }),
    );
  }

  public update({
    data,
    endpoint,
    message,
  }: {
    data: T;
    endpoint: string;
    message: string;
  }): Observable<IResponse<T>> {
    const httpOption: IHttpOptions = {
      path: endpoint,
      body: data,
    };
    const { url, body } = this._httpOptionTool.runSendData(httpOption);
    return this._http.put<IResponse<T>>(url, body).pipe(
      catchError((error) => this.handleError<IResponse<T>>(message, error)),
      tap((res) => {
        if (res.data) {
          this.handleSuccess(message);
        }
      }),
    );
  }

  private handleSuccess<T>(message: string): void {
    this._messageService.add({
      key: '',
      severity: 'success',
      summary: 'Registro Creado!',
      detail: `${message} creado con éxito!.`,
    });
  }

  private handleDelete<T>(message: string): void {
    this._messageService.add({
      key: 'toast',
      severity: 'success',
      summary: 'Registro Eliminado!',
      detail: `${message} eliminado con éxito!.`,
    });
  }

  private handleError<T>(message: string, error: any): Observable<T> {
    console.error('Error en la solicitud:', error);
    this._messageService.add({
      key: 'toast',
      severity: 'warn',
      summary: `No se pudo realizar la operación: ${message}.`,
      detail: `${error.error.message}.`,
    });
    return throwError(() => new Error('Error en la solicitud'));
  }
}
